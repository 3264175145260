
@import url("https://fonts.googleapis.com/css2?family=Source+Code+Pro:wght@400;700&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;

::-webkit-scrollbar {
  display: none;
}
* {
  font-family: Source Code Pro, monospace;
}
.remove-scrollbar {
  -ms-overflow-style: none;
  /* IE + Edge */
  scrollbar-width: none;
  /* Firefox */
}

/* html {
  @apply dark:bg-black dark:text-white text-black bg-[#F9FAFB]  transition-colors duration-500
 
} */


p {
  @apply text-black dark:text-slate-300
}

h1{
  @apply text-3xl dark:text-white text-black
}

h2 {
  @apply text-2xl font-bold dark:text-white text-black
} 
h3 {
  @apply text-xl font-bold dark:text-white text-black
}
h4 {
  @apply text-lg font-bold dark:text-white text-black
}
h5{
    @apply  dark:text-white text-black
}

#page {
  @apply dark:bg-[#15191e] dark:text-white text-black bg-[#F9FAFB] transition-colors duration-500
}

#imgProjects{
  @apply border-4 rounded-xl border-neutral-400/20 dark:border-slate-600
}

.test .vertical-timeline-element-content {
  @apply dark:bg-[#0D1117] bg-[#f2f4f6] transition-colors duration-500
}

.secondary-bg-color {
  @apply dark:bg-[#1f252e] bg-[#EDEFF2] transition-colors duration-500
}

#skills li {
  @apply animationIn
}
.animationIn {
  animation: transitionIn 2s;
}

@keyframes transitionIn {
  0% {
      transform: translateY(90px); }
  50% {
    transform: translateY(-5px);
  }
  100%{
    transform: translateY(0);
  }
  
}
.pulseAnimation {
  animation: pulse 1s cubic-bezier(0.4, 0, 0.6, 1) infinite;
}


@keyframes pulse {

  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.meIconTransition {
  animation: meIcon 1s 1.5s backwards;

}

@keyframes meIcon {
  0% {
    transform: translateY(-200px);
  }

  50% {
    transform: translateY(10px);
  }

  100% {
    transform: translateY(0);
  }

}

.buttonAnimation {
  animation: buttonAn 1s 1s backwards;
}

@keyframes buttonAn {
  0%{
        opacity: 0;
    scale: 0;
  }
  50%{
    scale: 1.1;
  }

  100%{
    opacity: 1;
    scale: 1;
  }
  
}

#skills li p {
  opacity: 0;
  z-index: 1;
}

#skills  li:hover p {
  opacity: 1;
  z-index: 1;
}

.svgHover{
  animation: out;
  animation-duration: 1s;
  animation-fill-mode: forwards;
}
@keyframes out {
  from {
    transform: translateY(-10px);
  }

  to {
    transform: translateY(0px);
  }
}
.svgHover:hover{
  animation-name: in;
  animation-duration: 1s infinite;
  animation-fill-mode: forwards;
}
@keyframes in {
  from {
    transform: translateY(0px);
  }

  to {
    transform: translateY(-15px);
  }
}



